<template>
  <v-container
    id="dashboard-view"
    fluid
    tag="section"
  >
    <v-row>
      <v-col cols="12">
        <v-row>
          <v-col
            v-for="(chart, i) in charts"
            :key="`chart-${i}`"
            cols="12"
            md="6"
            lg="4"
          >
            <material-chart-card
              :color="chart.color"
              :data="chart.data"
              :options="chart.options"
              :responsive-options="chart.responsiveOptions"
              :title="chart.title"
              :type="chart.type"
            >
              <template #subtitle>
                <div class="font-weight-light text--secondary">
                  <div v-html="chart.subtitle" />
                </div>
              </template>

              <template #actions>
                <v-icon
                  class="mr-1"
                  small
                >
                  mdi-clock-outline
                </v-icon>

                <span
                  class="text-caption grey--text font-weight-light"
                  v-text="chart.time"
                />
              </template>
            </material-chart-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        v-for="({ actionIcon, actionText, ...attrs }, i) in this.inTestingItems"
        :key="i"
        cols="6"
        md="6"
        lg="3"
      >
        <material-stat-card v-bind="attrs">
          <template #actions>
            <v-icon
              class="mr-2"
              small
              v-text="actionIcon"
            />
            <div class="text-truncate">
              {{ actionText }}
            </div>
          </template>
        </material-stat-card>
      </v-col>
      <v-col
        cols="12"
      >
        <material-card
          color="orange"
          full-header
        >
          <template #heading>
            <div class="pa-8 white--text">
              <div class="text-h4 font-weight-light">
                Request Due Item Status
              </div>
              <div class="text-caption">
                Recent 3 Month Request Due Items
              </div>
            </div>
          </template>
          <v-card-text>
            <v-data-table
              :headers="requestDueItemsHeaders"
              :items="requestDueItems"
              dense
              :items-per-page="5"
            />
          </v-card-text>
        </material-card>
      </v-col>
      <!--
      <v-col
        cols="12"
        md="6"
      >
        <material-card
          color="accent"
          full-header
        >
          <template #heading>
            <div class="pa-8 white--text">
              <div class="text-h4 font-weight-light">
                Your Follow Item Status
              </div>
              <div class="text-caption">
                Last Update DateTime
              </div>
            </div>
          </template>
          <v-card-text>
            <v-data-table
              :headers="followItemsHeaders"
              :items="userFollowItems"
            />
          </v-card-text>
        </material-card>
      </v-col>
      -->
    </v-row>
  </v-container>
</template>

<script>
  // Utilities
  import { get } from 'vuex-pathify'
  import Vue from 'vue'
  import dashBoardApi from '../api/dashboard-statistics'
  import chartDataHandle from '../util/chart-data-handle'
  import MaterialChartCard from '../components/MaterialChartCard'
  import ReceiptItemsDataHandle from '@/util/receipt-item-data-handle'
  const lineSmooth = Vue.chartist.Interpolation.cardinal({
    tension: 0,
  })

  export default {
    name: 'DashboardView',
    components: { MaterialChartCard },
    data: () => ({
      updateDate: '',
      followItemsHeaders: [
        { sortable: true, text: 'ID', value: 'id' },
        { text: 'Receipt #', value: 'receipt_number' },
        { sortable: false, text: 'PN', value: 'item_part_number' },
        { sortable: false, text: 'Qty', value: 'label_show_quantity', align: 'right' },
        { sortable: true, text: 'Status', value: 'task_status.name', align: 'right' },
        { sortable: true, text: 'Remain', value: 'estimate_due_date', align: 'right' },
      ],
      requestDueItemsHeaders: [
        { sortable: true, text: 'ID', value: 'id' },
        { text: 'Receipt #', value: 'item_receipt.po_number' },
        { sortable: false, text: 'PN', value: 'item_part_number' },
        { sortable: false, text: 'Qty', value: 'label_show_quantity', align: 'right' },
        { sortable: true, text: 'Status', value: 'task_status.name', align: 'right' },
        { sortable: true, text: 'Remain', value: 'remain_times', align: 'right' },
      ],
      userFollowItems: [],
      requestDueItems: [],
      inTestingItems: [],
      charts: [{
        type: 'Bar',
        color: 'primary',
        title: 'Month Created',
        subtitle: 'Item Quantity',
        time: 'updated 0 minutes ago',
        data: {
          labels: [],
          series: [],
        },
        options: {
          axisX: {
            showGrid: false,
          },
          // low: 500,
          // high: 4000,
          chartPadding: {
            top: 0,
            right: 5,
            bottom: 0,
            left: 0,
          },
        },
        responsiveOptions: [
          ['screen and (max-width: 640px)', {
            seriesBarDistance: 5,
            axisX: {
              labelInterpolationFnc: function (value) {
                return value[0]
              },
            },
          }],
        ],
      }, {
        type: 'Line',
        color: 'success',
        title: 'Month Done',
        subtitle: 'Item Quantity',
        time: 'updated 0 minutes ago',
        data: {
          labels: [],
          series: [],
        },
        options: {
          lineSmooth,
          // low: 500,
          // high: 4000, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
          chartPadding: {
            top: 0,
            right: 5,
            bottom: 0,
            left: 0,
          },
        },
      }, {
        type: 'Bar',
        color: 'warning',
        title: 'Estimate Due Order',
        subtitle: 'Total',
        time: 'updated 0 minutes ago',
        data: {
          labels: [],
          series: [],
        },
        options: {
          axisX: {
            showGrid: false,
          },
          // low: 0,
          // high: 2000,
          chartPadding: {
            top: 0,
            right: 5,
            bottom: 0,
            left: 0,
          },
        },
        responsiveOptions: [
          ['screen and (max-width: 640px)', {
            seriesBarDistance: 5,
            axisX: {
              labelInterpolationFnc: function (value) {
                return value[0]
              },
            },
          }],
        ],
      }],
    }),
    created () {
      this.requestUserFollowItem()
      this.requestRequestDueItem()
      this.requestSummaryReport()
      this.requestTestReport()
      this.updateDate = Date().toString()
    },
    computed: {
      sales: get('sales/sales'),
      totalSales () {
        return this.sales.reduce((acc, val) => acc + val.salesInM, 0)
      },
    },
    methods: {
      requestUserFollowItem () {
        dashBoardApi.userFollowItems().then(response => {
          this.userFollowItems = response.data.data
        })
      },
      requestRequestDueItem () {
        dashBoardApi.requestDueItems().then(response => {
          this.requestDueItems = ReceiptItemsDataHandle.dataFormatArray(response.data.data)
        })
      },
      requestSummaryReport () {
        this.inTestingItems = []
        dashBoardApi.summaryReport().then(response => {
          const data = response.data.data
          let createdReport = data.receiptItemStatus.find(function (item) {
            return item.status === 'created'
          })
          if (createdReport === undefined) createdReport = { inTestingQuantity: '0', status: 'created' }
          let processingReport = data.receiptItemStatus.find(function (item) {
            return item.status === 'processing'
          })
          if (processingReport === undefined) processingReport = { inTestingQuantity: '0', status: 'processing' }
          this.inTestingItems.push(
            {
              actionIcon: 'mdi-update',
              actionText: 'Updated ' + this.updateDate,
              color: 'info',
              icon: 'mdi-pencil-outline',
              title: 'Create Items',
              value: createdReport.inTestingQuantity,
            },
            {
              actionIcon: 'mdi-update',
              actionText: 'Updated ' + this.updateDate,
              color: 'primary',
              icon: 'mdi-arrow-right-bold',
              title: 'Processing',
              value: processingReport.inTestingQuantity,
            },
            {
              actionIcon: 'mdi-update',
              actionText: 'Updated ' + this.updateDate,
              color: 'secondary',
              icon: 'mdi-calendar-month',
              title: 'Net Order',
              value: data.netItemsCount,
            },
            {
              actionIcon: 'mdi-update',
              actionText: 'Updated ' + this.updateDate,
              color: 'warning',
              icon: 'mdi-cart-plus',
              title: 'Request',
              value: data.requestItemsNumber,
            },
          )
        }).catch(error => {
          console.log(error)
        })
      },
      requestTestReport () {
        dashBoardApi.testReport().then(response => {
          const chartData = chartDataHandle.dashboardChart(response.data.data)
          this.charts[0].data.labels = chartData.monthCreateData.labelData
          this.charts[0].data.series.push(chartData.monthCreateData.seriesData)
          this.charts[0].time = this.updateDate
          this.charts[1].data.labels = chartData.monthDoneData.labelData
          this.charts[1].data.series.push(chartData.monthDoneData.seriesData)
          this.charts[1].time = this.updateDate
          this.charts[2].data.labels = chartData.estimateData.labelData
          this.charts[2].data.series.push(chartData.estimateData.seriesData)
          this.charts[2].subtitle = 'Delay before ' + response.data.data.dateSelected.delayItemDate + ' Total items: ' + response.data.data.delayTestItem
          this.charts[2].time = this.updateDate
          console.log(chartData)
        })
      },
    },
  }
</script>
